import { z } from "zod";

export const runProcess = z.object({
  run_id: z.string().min(1).optional(),
  target_entity: z.string().min(1),
  process_step_name: z.string().min(1),
  input: z.string().min(1),
  priority: z.number().int().optional(),
  budget: z.number().int().optional(),
});

export type RunProcess = z.infer<typeof runProcess>;
