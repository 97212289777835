import * as React from "react";

import { classNames } from "../../lib/utils";

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, rows, label, ...props }, ref) => {
  return (
    <div>
      {label && <label htmlFor={props.name}>{label}</label>}
      <textarea
        ref={ref}
        rows={rows || 4}
        className={classNames(
          "flex w-full rounded-md border border-theme-border bg-white p-4 text-base text-theme-text-body transition-colors file:border-0 file:bg-white file:text-sm file:font-medium placeholder:text-sm placeholder:text-theme-text-input hover:border-primary focus:border-primary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
          className,
        )}
        {...props}
      />
    </div>
  );
});

TextArea.displayName = "Input";

export { TextArea };
