import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Button } from "../../components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Select } from "../../components/ui/select";
import { TextArea } from "../../components/ui/textarea";
import { TARGET_ENTITY_OPTIONS } from "../../constants/run-process";
import { RunProcess, runProcess } from "../../constants/validations/run-process";
import { classNames } from "../../lib/utils";
import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";

export function RunProcessPage() {
  const formUrl = useForm<RunProcess>({
    resolver: zodResolver(runProcess),
    defaultValues: {
      priority: 1,
      budget: 2,
      target_entity: "biz_domain",
    },
  });

  return (
    <div className={classNames("grid min-h-screen")}>
      <PageHeader colorTheme="blue" />

      <main className={classNames("size-full px-4 pb-14", "pt-14")}>
        <div className="mx-auto grid w-full max-w-6xl gap-10">
          <div className="grid gap-5 align-baseline md:grid-cols-1 md:gap-y-10">
            <Form {...formUrl}>
              <form
                className="mx-auto grid w-full max-w-2xl gap-6 rounded-2xl border border-theme-border bg-white px-24 py-12"
                onSubmit={formUrl.handleSubmit((data) => window?.alert(JSON.stringify(data)))}
              >
                <FormField
                  name="run_id"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input label="Run id" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="target_entity"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Select
                          label="Target entity"
                          {...field}
                          options={TARGET_ENTITY_OPTIONS.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="process_step_name"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input label="Process step name" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="input"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <TextArea label="Input" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="priority"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input label="Priority" type="number" {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="budget"
                  control={formUrl.control}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input label="Budget" type="number" step={0.1} {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button type="submit">Submit</Button>
              </form>
            </Form>
          </div>
        </div>
      </main>

      <PageFooter displayContact={false} />
    </div>
  );
}
export default RunProcessPage;
