import { FC, type MouseEventHandler } from "react";

import { getHostnameFromURL, isUrl } from "../../../../lib/utils";
import { ShowMoreButton } from "../../../extra/show-more-button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../ui/accordion";
import DetailRow from "../../detail-row";

export type ScoreCategoriesV2Props = {
  tooltipId: string;
  onCopyLink: (params: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>;
  copiedText: string;
  // TODO: domanskyi - fix typing!!!!!!!!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedDetails: any;
  isGroupedDetailsExpanded: (title: string) => boolean;
  onToggleExpandedDetailsBlock: (title: string) => void;
};

export const ScoreCategoriesV2: FC<ScoreCategoriesV2Props> = ({
  tooltipId,
  copiedText,
  groupedDetails,
  isGroupedDetailsExpanded,
  onToggleExpandedDetailsBlock,
}) => (
  <div className="grid gap-8">
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.entries(groupedDetails).map(([title, groups]: [string, any]) => {
        return (
          <div className="relative grid gap-3 border-b pb-8 pl-64 last:border-none last:pb-0">
            <h4 className="absolute left-0 my-1.5 max-w-60 text-base font-bold text-theme-text-body">
              {title} ({groups.length})
            </h4>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
              }}
            >
              {(isGroupedDetailsExpanded(title) ? groups : groups.slice(0, 5)).map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ([visible_value, details]: [any, any]) => {
                  if (details.length === 1) {
                    return (
                      <div
                        className="text-sm font-medium"
                        style={{
                          paddingTop: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          details.map((detail: any) => (
                            <DetailRow detail={detail} />
                          ))
                        }
                      </div>
                    );
                  }

                  return (
                    <Accordion type="single" collapsible>
                      <AccordionItem value={visible_value}>
                        <AccordionTrigger chevronPosition="start">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              paddingLeft: "0.25rem",
                            }}
                          >
                            <span>
                              {isUrl(visible_value) ? (
                                <a href={visible_value} className="underline">
                                  {getHostnameFromURL(visible_value)}
                                </a>
                              ) : (
                                visible_value
                              )}
                            </span>{" "}
                            <span>{`${details.length} ${details.length === 1 ? "source" : "sources"}`}</span>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div
                            style={{
                              paddingLeft: "20px",
                            }}
                          >
                            {
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              details.map((detail: any) => (
                                <DetailRow detail={detail} />
                              ))
                            }
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  );
                },
              )}
              {groups?.length > 5 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <ShowMoreButton
                    onClick={() => onToggleExpandedDetailsBlock(title)}
                    isShowingMore={isGroupedDetailsExpanded(title)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })
    }
  </div>
);
