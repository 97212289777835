import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { GoArrowRight } from "react-icons/go";
import { useSearchParams } from "react-router-dom";

import { Button } from "../../components/ui/button";
import { Form, FormControl, FormField, FormItem } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { ProcessIdSchema, processIdSchema } from "../../constants/validations/process-steps";

type ProcessStepsFormProps = {
  isLoading: boolean;
};

const ProcessStepsForm: FC<ProcessStepsFormProps> = ({ isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const formUrl = useForm<ProcessIdSchema>({
    values: {
      processId: searchParams.get("processId") || "",
    },
    resolver: zodResolver(processIdSchema),
  });

  const onSubmit = useCallback<SubmitHandler<ProcessIdSchema>>(
    async (data) => {
      if (data) {
        setSearchParams(
          {
            ...(searchParams.get("url") ? { url: searchParams.get("url") as string } : {}),
            ...(searchParams.get("v") ? { url: searchParams.get("v") as string } : {}),
            processId: data.processId as string,
          },
          {
            replace: true,
          },
        );
      }
    },
    [searchParams, setSearchParams],
  );

  return (
    <div className="flex w-full">
      <Form {...formUrl}>
        <form className="grid grid-cols-[1fr,auto] gap-2" onSubmit={formUrl.handleSubmit(onSubmit)}>
          <FormField
            name="processId"
            control={formUrl.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Process id" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <Button type="submit" disabled={formUrl.formState.isSubmitting || isLoading}>
            Find process
            {formUrl.formState.isSubmitting || isLoading ? (
              <CgSpinner className="ml-3 size-5 animate-spin" />
            ) : (
              <GoArrowRight className="ml-3 size-5" />
            )}
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default ProcessStepsForm;
