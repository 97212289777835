import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function classNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// https://www.30secondsofcode.org/js/s/compact-join-array
export function compactJoin(array: unknown[], separator = " ") {
  return array.filter(Boolean).join(separator);
}

export function isUrl(value: string): boolean {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
}

export function getHostnameFromURL(url: string, limit: number = 80): string {
  try {
    return new URL(url).hostname;
  } catch {
    if (url?.length > limit) {
      return url?.substring(0, limit) + "...";
    } else {
      return url;
    }
  }
}
