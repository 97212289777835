/**
 * Build API URL query parameter
 * @param queryObj object
 * @returns api urlquery parameters
 */
export const buildApiURLQuery = (queryObj: object) =>
  Object.entries(queryObj)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

type Environment = "local" | "qa" | "production" | "unknown";

export const getEnvironment = (): Environment => {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "local";
  } else if (hostname.includes("qa-kyb-bluearc.firebaseapp.com") || hostname.includes("qa-kyb-bluearc.web.app")) {
    return "qa";
  } else if (hostname.includes("bluearc.ai")) {
    return "production";
  } else {
    return "unknown";
  }
};
