import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCopy } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";

import { classNames } from "../../lib/utils";
import { ProcessStep } from "../../Services/Types";

interface TreeProps {
  isLoading: boolean;
  data: ProcessStep[];
  defaultOpenLevels?: number;
}

interface TreeNodeComponentProps {
  node: ProcessStep;
  level: number;
  defaultOpenLevels: number;
}

const formatDateTime = (dateTimeString: string): string => {
  const dateObj = new Date(dateTimeString);
  return `${dateObj.getFullYear()}-${("0" + (dateObj.getMonth() + 1)).slice(-2)}-${("0" + dateObj.getDate()).slice(-2)}T${("0" + dateObj.getHours()).slice(-2)}:${("0" + dateObj.getMinutes()).slice(-2)}:${("0" + dateObj.getSeconds()).slice(-2)}`;
};

const findMaxDepth = (nodes: ProcessStep[], currentDepth = 1): number => {
  let maxDepth = currentDepth;

  nodes?.forEach((node) => {
    if (node.children && node.children.length > 0) {
      maxDepth = Math.max(maxDepth, findMaxDepth(node.children, currentDepth + 1));
    }
  });

  return maxDepth;
};

const TreeNodeComponent: React.FC<TreeNodeComponentProps & { maxExpansionLevel: number }> = ({
  node,
  level,
  defaultOpenLevels,
  maxExpansionLevel,
}) => {
  const [isExpanded, setIsExpanded] = useState(level <= maxExpansionLevel); // Initial state based on level

  useEffect(() => {
    // Update the expanded state based on maxExpansionLevel change
    setIsExpanded(level <= maxExpansionLevel);
  }, [maxExpansionLevel, level]); // Re-run when maxExpansionLevel or level changes

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleShowMoreToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const shouldRenderChildren = isExpanded && node.children && node.children.length > 0;
  const openLevels = defaultOpenLevels ? defaultOpenLevels : 3;
  const processId = node.process_id || node.process_step_instance_id;
  const formattedDatePt = node.date_pt ? formatDateTime(node.date_pt) : "";

  return (
    <div className="ml-5 first:ml-0">
      <div
        onClick={handleExpandCollapse}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") handleExpandCollapse();
        }}
        tabIndex={0}
        role="treeitem"
        aria-expanded={isExpanded}
        style={{
          cursor: "pointer",
          outline: "none",
          padding: "5px",
          borderRadius: "4px",
        }}
        className="flex items-center justify-between gap-2 border-b last:pb-0"
        aria-label={`${processId} node`}
      >
        <div className="flex items-center gap-2 truncate text-base last:pb-0">
          {node.children && node.children.length > 0 && (
            <GoChevronDown
              className={classNames(
                "size-4 shrink-0 text-current transition-transform duration-300",
                isExpanded ? "rotate-[360deg]" : "rotate-[270deg]",
              )}
            />
          )}
          <p className={classNames("my-1.5 w-full truncate text-base font-normal text-theme-text-body", "md:w-auto")}>
            {processId}
          </p>
          {formattedDatePt && <span>|</span>}
          {formattedDatePt && <div className="truncate text-base">{formattedDatePt}</div>}
          {node.run_ids && node.run_ids?.length > 0 && <span>|</span>}
          {node.run_ids && node.run_ids?.length > 0 && (
            <div className="truncate text-base">Run IDs: {node.run_ids?.join(", ") || "-"}</div>
          )}
          <FaRegCopy
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(node.process_step_instance_id);
            }}
            className="size-3"
          />
        </div>
        <a
          href={node.source_url?.startsWith("https://") ? node.source_url : `https://${node.source_url}`}
          className="my-1.5 w-full max-w-fit truncate text-base font-normal text-primary underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.source_domain}
        </a>
      </div>

      {shouldRenderChildren && (
        <>
          <ul role="group" style={{ paddingLeft: "20px" }}>
            {node.children &&
              node.children.slice(0, isCollapsed ? openLevels : node.children.length).map((child, idx) => (
                <li key={idx}>
                  <TreeNodeComponent
                    key={idx}
                    node={child}
                    level={level + 1}
                    defaultOpenLevels={defaultOpenLevels}
                    maxExpansionLevel={maxExpansionLevel} // Pass down current expansion level
                  />
                </li>
              ))}
          </ul>
          {node.children && node.children.length > openLevels && (
            <div
              onClick={handleShowMoreToggle}
              className="my-1.5 w-full max-w-fit truncate text-base font-normal text-primary"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") handleShowMoreToggle();
              }}
              tabIndex={0}
              aria-label={isCollapsed ? "Show more" : "Show less"}
            >
              {isCollapsed ? "Show more..." : "Show less"}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ProcessSteps: React.FC<TreeProps> = ({ data = [], defaultOpenLevels = 3, isLoading }) => {
  const [totalExpansionLevel, setTotalExpansionLevel] = useState(findMaxDepth(data));
  const [maxExpansionLevel, setMaxExpansionLevel] = useState(defaultOpenLevels);

  const handleLevelChange = (level: number) => {
    setMaxExpansionLevel(level); // Update max expansion level based on the button clicked
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setTotalExpansionLevel(findMaxDepth(data)); // Dynamically calculate the max depth based on data
    }
  }, [data]);

  if (isLoading || !data || !data.length) {
    return (
      <div className="relative grid scroll-mt-40 content-start gap-8 rounded-2xl border bg-white p-8 md:col-span-2">
        <span className="w-full text-center">
          {isLoading ? (
            <CgSpinner
              className="size-5 animate-spin"
              style={{
                margin: "0 auto",
              }}
            />
          ) : (
            "No data available"
          )}
        </span>
      </div>
    );
  }

  return (
    <div className="relative grid scroll-mt-40 content-start gap-8 rounded-2xl border bg-white p-8 md:col-span-2">
      {/* Level control buttons */}
      <div className="mb-4 flex gap-4">
        {Array.from({ length: totalExpansionLevel }, (_, i) => i + 1).map((level) => (
          <button
            key={level}
            onClick={() => handleLevelChange(level)} // Change expansion level
            className={`rounded px-3 py-1 ${level === maxExpansionLevel ? "bg-primary text-white" : "bg-gray-200"}`}
          >
            L{level}
          </button>
        ))}
      </div>

      <div role="tree" aria-label="ProcessStepsTree" className="overflow-x-auto">
        {/* Render tree nodes */}
        {data.map((node, index) => (
          <TreeNodeComponent
            key={index}
            node={node}
            level={1}
            defaultOpenLevels={defaultOpenLevels}
            maxExpansionLevel={maxExpansionLevel} // Pass current expansion level down to nodes
          />
        ))}
      </div>
    </div>
  );
};

export default ProcessSteps;
