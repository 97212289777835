import * as React from "react";

import { classNames } from "../../lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, label, ...props }, ref) => {
  console.log(props);

  return (
    <div>
      {label && <label htmlFor={props.name}>{label}</label>}
      <input
        type={type}
        className={classNames(
          "flex h-10 w-full rounded-md border border-theme-border bg-white px-4 py-0 text-base text-theme-text-body transition-colors file:border-0 file:bg-white file:text-sm file:font-medium placeholder:text-sm placeholder:text-theme-text-input hover:border-primary focus:border-primary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
          className,
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});

Input.displayName = "Input";

export { Input };
